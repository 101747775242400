/* Provide sufficient contrast against white background */
.circle-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.circle {
  width: 250px; 
  height: 250px;
  border-radius: 50%;
  border: 6px solid #355A99;
  display: flex; /* or inline-flex */
  align-items: center; 
  justify-content: center;
}

.rccs-size {
  width: 2000px
}